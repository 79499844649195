import React from 'react'
import './SuperResultsTimes.scss'
import { AVAILABLE_APPOINTMENTS_ENDPOINT } from '../../../../../shared/constants/api-helpers'
import { fetchAPIData } from '../../../../../shared/services/fetchData'
import SVGInline from 'react-svg-inline'
import CalendarIcon from '../../../../../shared/assets/calendar-drawers.svg'
import InfoIcon from '../../../../../shared/assets/info-reviews-drawer.svg'
import ArrowLeft from '../../../../../shared/assets/left.svg'
import ArrowRight from '../../../../../shared/assets/right.svg'
import moment from 'moment'
import { cleanNumber } from '../../../../../shared/utility-functions'
import isDesktop from '../../../../../shared/utility-functions/isDesktop'
import { buildDoctorUrl } from '../../../../../shared/utility-functions'
import { isOBGYN } from '../../../../../shared/utility-functions/isOBGYN.js'
import { isPrimaryCare } from '../../../../../shared/utility-functions/isPrimaryCare.js'
import OBGYNQuestionaireConnector from '../../../../../Blocks/QuestionnaireModal/OBGYNQuestionaire/OBGYNQuestionaireConnector.jsx'
import { scheduleButtonEvent } from '../../../../../shared/utility-functions/googleTagManager.js'
import { clickToCallTransformer } from '../../../../../shared/data-mappers/click-to-call-transformer'
import { clickToCallEvent } from '../../../../../shared/utility-functions/googleTagManager.js'
import { obOnlineSchedulingConstants } from './obOnlineSchedulingConstants.js'

class SuperResultsTimes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentWeek: this.getCurrentWorkWeek(),
            appointmentTimes: [],
            isLoading: false,
            initialFetchDone: false,
            fetchFailed: false,
            firstAvailableDate: null,
            cannotSchedule: false,
            showPreliminaryQuestions: false,
            renderTimeSlots: this.props.renderTimeSlots
        }

        this.fetchAppointmentTimes = this.fetchAppointmentTimes.bind(this)
        this.navigateWeeks = this.navigateWeeks.bind(this)
        this.getCurrentWorkWeek = this.getCurrentWorkWeek.bind(this)
        this.getNextWorkWeek = this.getNextWorkWeek.bind(this)
        this.formatDate = this.formatDate.bind(this)
        this.generateWeekDayLabels = this.generateWeekDayLabels.bind(this)
        this.checkIfCurrentWeek = this.checkIfCurrentWeek.bind(this)
        this.convertHours = this.convertHours.bind(this)
        this.getDateFromString = this.getDateFromString.bind(this)
        this.isMoreThanSixMonthsFromToday = this.isMoreThanSixMonthsFromToday.bind(this)
        this.getPrimaryAddressDepartmentID = this.getPrimaryAddressDepartmentID.bind(this)
        this.onTimeClick = this.onTimeClick.bind(this)
        this.onMoreAvailabilityClick = this.onMoreAvailabilityClick.bind(this)
        this.setShowPreliminaryQuestions = this.setShowPreliminaryQuestions.bind(this)
        this.setShowPreliminaryQuestionsFalse = this.setShowPreliminaryQuestionsFalse.bind(this)
        this.clickToCall = this.clickToCall.bind(this)
    }

    componentDidMount() {
        this.fetchAppointmentTimes()
    }

    componentDidUpdate(prevProps, prevState) {
            if (this.props.provider !== prevProps.provider) {
                this.setState({
                    initialFetchDone: false,
                    currentWeek: this.getCurrentWorkWeek(),
                    firstAvailableDate: null,
                    appointmentTimes: [],
                    isLoading: false,
                    fetchFailed: false,
                    cannotSchedule: false,
                });
                this.fetchAppointmentTimes();
                return;
            }

            if (this.props.selectedLocation !== prevProps.selectedLocation) {
                this.fetchAppointmentTimes();
                return;
            }

            if (this.state.currentWeek !== prevState.currentWeek) {
                if (this.isMoreThanSixMonthsFromToday(this.state.currentWeek.start)) {
                    this.setState({ cannotSchedule: true, isLoading: false });
                    return;
                }

                this.fetchAppointmentTimes();
                if (this.state.firstAvailableDate && this.state.firstAvailableDate.length !== 0) this.checkIfCurrentWeek();
            }

            if (this.props.preliminaryStatus !== prevProps.preliminaryStatus) {
                this.setState({
                    initialFetchDone: false,
                    currentWeek: this.getCurrentWorkWeek()
                })
                this.fetchAppointmentTimes();
                return;
            }
    }

    clickToCall() {
        clickToCallEvent(clickToCallTransformer(this.props.flow, this.props.provider))
    }

    onMoreAvailabilityClick(doctor) {
        window.sessionStorage.removeItem('fadSelectedDoctor')
        window.sessionStorage.removeItem('fadSelectedLocation')
        window.sessionStorage.removeItem('fadSelectedAppointment')
        const openSchedulingURL = doctor ? buildDoctorUrl('/schedule/', { name: doctor.title, npi: doctor.npi }) : null
        this.props.scheduleLinkClick(openSchedulingURL)
    }

    onTimeClick(doctor, date, time) {
        const dataLayerObject = {
            flow: this.props.flow,
            providerName: doctor.title,
            providerID: doctor.id,
            providerNPI: doctor.npi,
            providerSpecialty: doctor.specialties ? doctor.specialties.join(', ') : undefined,
            facilityName: undefined,
            facilityAddress: undefined
        }

        scheduleButtonEvent(dataLayerObject)
        const openSchedulingURL = doctor ? buildDoctorUrl('/schedule/', { name: doctor.title, npi: doctor.npi }) : null
        /* We are only doing this because of Test data is not matching with production data as result we are missing all assign doctor locations which missing departmentid */

        if (this.props.isOBS && isOBGYN(this.props.provider.specialtyIds)) {
            const location = doctor.locations.length > 1 ? doctor.locations.find(loc => loc.departmentId == time.DepartmentId) : doctor.locations[0];
            sessionStorage.setItem('fadSelectedLocation', JSON.stringify(location))
        } else {
            const location = doctor.locations.find(loc => loc.departmentId == time.DepartmentId)
            sessionStorage.setItem('fadSelectedLocation', JSON.stringify(location))
        }

        const appointment = {
            Date: date,
            Time: time
        }
        sessionStorage.setItem('fadSelectedAppointment', JSON.stringify(appointment))
        sessionStorage.setItem('fadSelectedDoctor', JSON.stringify(doctor))
        sessionStorage.setItem('search-path', window.location.href)

        if (isOBGYN(this.props.provider.specialtyIds))
            window.location.href = openSchedulingURL; //this.props.obgynScheduleLinkClick(openSchedulingURL, doctor.phone)
        else this.props.scheduleLinkClick(openSchedulingURL)

        if (this.props.from === 'provider-drawer') {
            this.props.handleCloseAll()
        }
    }

    setShowPreliminaryQuestions() {
        if ((this.props.from === 'provider-drawer') && (this.props.setShowQuestions)) {
            this.props.setShowQuestions(true)
        }
        else {
            this.setState({ showPreliminaryQuestions: true, renderTimeSlots: this.props.isOBS && isOBGYN(this.props.provider.specialtyIds) ? false : true })
        }
    }

    setShowPreliminaryQuestionsFalse() {
        this.setState({ showPreliminaryQuestions: false })
    }

    async fetchAppointmentTimes() {
        if (this.props.provider.epicId === null || this.props.provider.epicId === 'NOT ENABLED' || !this.props.provider.hasScheduleOnline) {
            this.setState({ cannotSchedule: true })
            return
        }

        if (isOBGYN(this.props.provider.specialtyIds) && !this.props.preliminaryStatus) return

        this.setState({ isLoading: true })

        let params = {
            endPoint: AVAILABLE_APPOINTMENTS_ENDPOINT,
            schedulingType: 'Open',
            startDate: this.state.currentWeek.start,
            endDate: this.state.currentWeek.end,
            physicianId: this.props.provider.epicId,
            weekIdentifier: this.state.currentWeek.start
        }

        const visitTypeID = window.sessionStorage.getItem('visitTypeOBGYN')
        if (visitTypeID && isOBGYN(this.props.provider.specialtyIds)) params.visitTypeId = visitTypeID

        // Set location
        if (this.props.superLocationCardAltLocation) { // location read from api/findalocation/get
            console.log('this.props.superLocationCardAltLocation if statement called')
            let epicIDs = this.props.superLocationCardAltLocation.EpicID.split(/,\s*/)
            params.departmentId = epicIDs[0].trim()
        }
        else {
            const departmentId = this.getPrimaryAddressDepartmentID()
            if (departmentId) {
                params.departmentId = departmentId
            }
            if (this.props.selectedLocation && this.props.selectedLocation.departmentId) {
                params.departmentId = this.props.selectedLocation.departmentId
            }
        }

        if (this.props.isOBS && isOBGYN(this.props.provider.specialtyIds)) {
            const filteredData = obOnlineSchedulingConstants.find(it => it.DepartmentId == params.departmentId && it.ProviderId == params.physicianId);
            if (filteredData !== undefined) {
                params.physicianId = filteredData.NurseId;
                params.visitTypeId = filteredData.VisitTypeID;
                params.startDate = window.sessionStorage.getItem('scheduleDate') ? new Date(window.sessionStorage.getItem('scheduleDate')) : this.state.currentWeek.start;
                params.endDate = this.state.currentWeek.end;
            } else {
                this.setState({ cannotSchedule: true, isLoading: false })
                return
            }
        }

        try {
            const result = await fetchAPIData(params)
            if (result.weekIdentifier === this.state.currentWeek.start) {
                if (!result || !result.Success) {
                    this.setState({ fetchFailed: true, isLoading: false })
                    return
                } else {
                    this.setState({ fetchFailed: false })
                }

                const times = result.Dates ? result.Dates : []
                if (times.length === 0 && !this.state.initialFetchDone) {
                    this.setState({
                        currentWeek: this.getNextWorkWeek(this.state.currentWeek)
                    })
                } else {
                    // Check for duplicate dates, make unique array
                    const uniqueMap = new Map(times.map(time => [time.Date, time]))
                    const uniqueTimes = Array.from(uniqueMap.values())

                    if (!this.state.initialFetchDone) {
                        this.setState({ firstAvailableDate: uniqueTimes[0].Date })
                    }

                    this.setState({
                        appointmentTimes: uniqueTimes,
                        initialFetchDone: true,
                        isLoading: false
                    })
                }
            }
        } catch (e) {
            console.log('Error fetching appointment times:', error)
            this.setState({ fetchFailed: true, isLoading: false })
        }
    }

    navigateWeeks(direction) {
        this.setState({
            currentWeek: this.getNextWorkWeek(this.state.currentWeek, direction)
        })
    }

    checkIfCurrentWeek() {
        if (this.state.currentWeek && this.state.currentWeek.start && this.state.firstAvailableDate) {
            const currentWeek = this.getDateFromString(this.state.currentWeek.start)
            const firstAvailableWeek = this.getDateFromString(this.state.firstAvailableDate)

            if (currentWeek <= firstAvailableWeek) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    getCurrentWorkWeek() {
        const currentDate = this.props.isOBS && isOBGYN(this.props.provider.specialtyIds) && window.sessionStorage.getItem('scheduleDate')
            ? new Date(window.sessionStorage.getItem('scheduleDate'))
            : new Date();
        const currentDayOfWeek = currentDate.getDay()
        const distanceToMonday = currentDayOfWeek === 0 ? -1 : currentDayOfWeek === 6 ? -2 : currentDayOfWeek - 1
        const distanceToFriday = currentDayOfWeek === 0 ? 5 : currentDayOfWeek === 6 ? 6 : 5 - currentDayOfWeek

        const weekStart = new Date(currentDate)
        weekStart.setDate(weekStart.getDate() - distanceToMonday)

        const weekEnd = new Date(currentDate)
        weekEnd.setDate(weekEnd.getDate() + distanceToFriday)

        return {
            start: this.formatDate(weekStart),
            end: this.formatDate(weekEnd)
        }
    }

    getNextWorkWeek(week, direction = 1) {
        const startDate = this.getDateFromString(week.start)
        startDate.setDate(startDate.getDate() + (7 * direction))

        const endDate = this.getDateFromString(week.end)
        endDate.setDate(endDate.getDate() + (7 * direction))

        return {
            start: this.formatDate(startDate),
            end: this.formatDate(endDate)
        }
    }

    formatDate(date) {
        const dd = String(date.getDate()).padStart(2, '0')
        const mm = String(date.getMonth() + 1).padStart(2, '0')
        const yyyy = date.getFullYear()

        return `${yyyy}-${mm}-${dd}`
    }

    getDateFromString(string) {
        const parts = string.split('-')
        const year = parseInt(parts[0], 10)
        const month = parseInt(parts[1], 10) - 1
        const day = parseInt(parts[2], 10)

        return new Date(year, month, day)
    }

    isMoreThanSixMonthsFromToday(date) {
        const today = new Date()
        const givenDate = this.getDateFromString(date)
        const monthsDifference = (givenDate.getFullYear() - today.getFullYear()) * 12 + givenDate.getMonth() - today.getMonth()

        return monthsDifference > 6 || (monthsDifference === 6 && givenDate.getDate() > today.getDate())
    }

    generateWeekDayLabels() {
        const { currentWeek } = this.state
        const start = this.getDateFromString(currentWeek.start)
        const weekDayLabels = []
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

        for (let i = 0; i < 5; i++) {
            const dayDate = new Date(start)
            dayDate.setDate(dayDate.getDate() + i)

            const month = monthNames[dayDate.getMonth()]
            const day = dayDate.getDate()

            weekDayLabels.push(`${month} ${day}`)
        }

        return weekDayLabels
    }

    convertHours(time24) {
        const time = moment(time24, 'HH:mm:ss')
        return time.format('h:mm A') // Formats to 'h:mm AM/PM'
    }

    getPrimaryAddressDepartmentID() {
        const primaryAddress = this.props.provider.address
        const locations = this.props.provider.locations
        if (!primaryAddress || !locations) return null

        for (let location of locations) {
            if (Math.abs(primaryAddress.lat - location.latitude) <= 0.03 && Math.abs(primaryAddress.lng - location.longitude) <= 0.03) {
                return location.departmentId
            }
        }
        return null
    }

    generateTimeSlotRows() {
        const { currentWeek, appointmentTimes } = this.state;
        let weekStart = this.getDateFromString(currentWeek.start)
        let firstRow = []
        let secondRow = []
        let thirdRow = []

        for (let i = 0; i < 5; i++) {
            let matchedDate = appointmentTimes.find(obj => obj.Date === this.formatDate(weekStart))

            if (matchedDate) {
                firstRow.push(<div onClick={matchedDate.Times[0] ? () => { this.onTimeClick(this.props.provider, matchedDate.Date, matchedDate.Times[0]) } : ''} className={matchedDate.Times[0] ? 'time-slot-chip' : 'time-slot-blank-chip'}>{matchedDate.Times[0] ? this.convertHours(matchedDate.Times[0].Time) : ''}</div>)
            } else {
                firstRow.push(<div className='time-slot-blank-chip' />)
            }

            weekStart.setDate(weekStart.getDate() + 1)
        }

        weekStart = this.getDateFromString(currentWeek.start)

        for (let i = 0; i < 5; i++) {
            let matchedDate = appointmentTimes.find(obj => obj.Date === this.formatDate(weekStart))

            if (matchedDate) {
                secondRow.push(<div onClick={matchedDate.Times[1] ? () => { this.onTimeClick(this.props.provider, matchedDate.Date, matchedDate.Times[1]) } : ''} className={matchedDate.Times[1] ? 'time-slot-chip' : 'time-slot-blank-chip'}>{matchedDate.Times[1] ? this.convertHours(matchedDate.Times[1].Time) : ''}</div>)
            } else {
                secondRow.push(<div className='time-slot-blank-chip' />)
            }

            weekStart.setDate(weekStart.getDate() + 1)
        }

        weekStart = this.getDateFromString(currentWeek.start)

        for (let i = 0; i < 5; i++) {
            let matchedDate = appointmentTimes.find(obj => obj.Date === this.formatDate(weekStart))

            if (matchedDate) {
                thirdRow.push(<div onClick={matchedDate.Times[2] ? () => { this.onTimeClick(this.props.provider, matchedDate.Date, matchedDate.Times[2]) } : ''} className={matchedDate.Times[2] ? 'time-slot-chip' : 'time-slot-blank-chip'}>{matchedDate.Times[2] ? this.convertHours(matchedDate.Times[2].Time) : ''}</div>)
            } else {
                thirdRow.push(<div className='time-slot-blank-chip' />)
            }

            weekStart.setDate(weekStart.getDate() + 1)
        }

        return (
            <div className='desktop-appointment-rows'>
                <span className='time-slot-rows'>
                    <div className='time-slot--row'>
                        {firstRow}
                    </div>
                    <div className='time-slot--row'>
                        {secondRow}
                    </div>
                    <div className='time-slot--row'>
                        {thirdRow}
                    </div>
                </span>
            </div>
        )
    }

    generateTimeSlotRowMobile() {
        const { appointmentTimes } = this.state

        let mobileRow = []
        for (let item of appointmentTimes) {
            let mobileTimes = []
            for (let time of item.Times) {
                mobileTimes.push(<div onClick={() => { this.onTimeClick(this.props.provider, item.Date, time) }} className='time-slot-chip'>{this.convertHours(time.Time)}</div>)
            }

            mobileRow.push(
                <div className='mobile-appointment-container'>
                    <div className='first-available-mobile'>
                        <SVGInline svg={CalendarIcon} className='calendar-icon' />{moment(item.Date, 'YYYY-MM-DD').format('dddd, MMMM Do')}
                    </div>
                    <div className='mobile-appointment-times'>
                        {mobileTimes}
                    </div>
                </div>
            )
        }

        return (
            <div className='mobile-appointment-rows'>
                {mobileRow[0] && mobileRow[0]}
            </div>
        )
    }

    render() {
        const { isLoading, cannotSchedule, showPreliminaryQuestions } = this.state
        const weekDayLabels = this.generateWeekDayLabels()
        const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']

        return (
            <div className='appointment-times--grid'>
                {showPreliminaryQuestions && isOBGYN(this.props.provider.specialtyIds) && this.props.preliminaryStatus !== true && (
                    <OBGYNQuestionaireConnector
                        setPreliminaryStatus={this.props.setPreliminaryStatus}
                        phone={this.props.provider.phone}
                        doctor={this.props.provider}
                        setShowQuestions={this.props.setShowQuestions}
                        setShowPreliminaryQuestionsFalse={this.setShowPreliminaryQuestionsFalse}
                        from={this.props.from}
                        setRenderTimeSlots={() => {
                            if (this.props.isOBS && isOBGYN(this.props.provider.specialtyIds)) {
                                this.setState({
                                    renderTimeSlots: true
                                })
                            }
                        }}
                    />
                )}
                {isLoading ? <p>Loading...</p>
                    : (cannotSchedule || (isOBGYN(this.props.provider.specialtyIds) && this.props.preliminaryStatus === false)) ? (
                        <div>
                            {!this.props.superLocationCardAltLocation && !this.props.provider.hasScheduleOnline &&
                                <div className='cannot-schedule'>
                                    <span>
                                        {!isDesktop(window.innerWidth) ? <SVGInline svg={InfoIcon} className='info-icon' /> : null}
                                        <span className='info-text'>To learn more about scheduling with this provider, please <a href={`tel:${cleanNumber(this.props.provider.phone)}`} onClick={() => this.clickToCall()}>call their office</a>.</span>
                                    </span>
                                </div>
                            }
                            {
                                !this.props.superLocationCardAltLocation && this.props.provider.hasScheduleOnline && !isPrimaryCare(this.props.provider.specialties) &&
                                <div className='cannot-schedule'>
                                    <span>
                                        {!isDesktop(window.innerWidth) ? <SVGInline svg={InfoIcon} className='info-icon' /> : null}
                                        <span className='info-text'>To learn more about scheduling with this provider, please <a href={`tel:${cleanNumber(this.props.provider.phone)}`} onClick={() => this.clickToCall()}>call their office</a>.</span>
                                    </span>
                                </div>
                            }
                            {
                                this.props.provider.hasScheduleOnline && isPrimaryCare(this.props.provider.specialties) &&
                                <div className='cannot-schedule'>
                                    <span>
                                        {!isDesktop(window.innerWidth) ? <SVGInline svg={InfoIcon} className='info-icon' /> : null}
                                        <span className='info-text'>To learn more about scheduling with this provider,  please <a href={`tel:${cleanNumber(this.props.provider.phone)}`} onClick={() => this.clickToCall()}>call their office</a>. Note: appointment availability changes on a daily basis — check again, as needed, to confirm open appointment times.</span>
                                    </span>
                                </div>
                            }
                            {this.props.superLocationCardAltLocation &&
                                <div className='cannot-schedule-text '>
                                    {isDesktop(window.innerWidth) &&
                                        <div>
                                            <p>Online scheduling is currently not available at this location. Choose another location or call the office to schedule.</p>
                                            <div className='super-location-phone-number'>
                                                <span>{this.props.superLocationCardAltLocationPhone}</span>
                                            </div>
                                        </div>
                                    }

                                    {!isDesktop(window.innerWidth) &&
                                        <div className='cannot-schedule-text-mobile'>
                                            <span>
                                                <SVGInline svg={InfoIcon} className='info-icon' />
                                                <span className='info-text'>Online scheduling is currently not available at this location. Choose another location or <a href={`tel:${cleanNumber(this.props.superLocationCardAltLocationPhone)}`} onClick={() => this.clickToCall()}>call the office to schedule.</a></span>
                                            </span>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    )
                        : isOBGYN(this.props.provider.specialtyIds) && this.props.preliminaryStatus === null
                            ? <div className='preliminary-questions'>
                                <span>
                                    {!isDesktop(window.innerWidth) ? <SVGInline svg={InfoIcon} className='info-icon' /> : null}
                                    <span className='info-text'>To schedule with this provider, you need to answer some preliminary questions.</span>
                                </span>
                                <div className='prelim-button' onClick={() => { this.setShowPreliminaryQuestions() }}>Proceed to Questions</div>
                            </div>
                            : <React.Fragment>
                                <div className='weekday-labels--row'>
                                    <div className={`arrow-container left ${this.checkIfCurrentWeek() ? 'disabled' : ''}`} onClick={this.checkIfCurrentWeek() ? null : () => { this.navigateWeeks(-1) }}><SVGInline svg={ArrowLeft} className={`weekday-arrow ${this.checkIfCurrentWeek() ? 'disabled' : ''}`} /></div>
                                    {
                                        weekDayLabels.map((label, index) => (
                                            <div key={label} className='weekday-label--container'>
                                                <span className='weekday-label--day'>{daysOfWeek[index]}</span>
                                                <span className='weekday-label--date'>{label}</span>
                                            </div>
                                        ))
                                    }
                                    <div className='arrow-container right' onClick={() => { this.navigateWeeks(1) }}><SVGInline svg={ArrowRight} className='weekday-arrow' /></div>
                                </div>
                                {this.generateTimeSlotRows()}
                                {this.generateTimeSlotRowMobile()}
                                <div className='show-more-availability'>
                                    {
                                        isDesktop(window.innerWidth)
                                        ? <a className='clickable' onClick={() => { this.props.timesDrawerClick() }}>Show More Availability</a>
                                        : <a className='clickable' onClick={() => { this.props.timesDrawerClick() }}>Show More Availability</a>
                                    }
                                </div>
                            </React.Fragment>}
            </div>
        )
    }
}

export default SuperResultsTimes
